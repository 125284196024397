import React from "react";

function MyFooter() {
  return (
    <div style={{ padding: "20px", background: "#f1f1f1", bottom: 0, width: "100%" }}>
      <div style={{ textAlign: "start", fontSize: "14px", lineHeight: "1.6" }}>
        <strong style={{ fontSize: "17px", color: "orangered"}}>Gudfynd Services Damage Compensation Policy</strong>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li>
            <strong>Damage Reporting:</strong> In the event of damage to the service provided, the customer must report it immediately to Gudfynd Services.
          </li>
          <li>
            <strong>Compensation:</strong> Gudfynd Services will offer compensation of <strong>5 times the cost of the service used, up to a maximum limit of ₹3000</strong>, whichever is lower.
          </li>
          <li>
            <strong>Alternative Option:</strong> If the customer prefers, they may choose to avail of additional services valued up to <strong>₹3000</strong> instead of monetary compensation.
          </li>
          <li>
            <strong>Eligibility:</strong> The policy applies only when damages are reported promptly and verified by Gudfynd Services.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MyFooter;
